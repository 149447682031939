import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.less";
import "./styles/app.scss";

import routeList from "./configs/routes.config";

const LoginPage = lazy(() => import("./container/authentication/login"));
const ExamineePage = lazy(() => import("./container/examinee/examinee"));

function App() {
  return (
    <Suspense fallback="loading...">
      <Routes>
        <Route path="/examinee" element={<ExamineePage />} />
        <Route path="/" element={<LoginPage />} />

        {routeList?.map((routeItem, routeIndex) => {
          const { element: Element, layout: Layout, path } = routeItem;
          return (
            <Route
              path={path}
              key={routeIndex}
              element={
                <Layout>
                  <Element />
                </Layout>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
}

export default App;
