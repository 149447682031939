import * as Async from "../components/asyncComponents";

interface RoutesInterface {
  element: any;
  layout: any;
  path: any;
  name: any;
}

const routesList: Array<RoutesInterface> = [
  {
    name: "Welcome",
    path: "/dashboard",
    layout: Async.PrivateLayout,
    element: Async.Welcome,
  },
  {
    name: "QuestionList",
    path: "/questionlist",
    layout: Async.PrivateLayout,
    element: Async.Examination,
    // element: Async.QuestionList,
  },
  {
    name: "Examination",
    path: "/examination",
    layout: Async.PrivateLayout,
    element: Async.Examination,
  },
  {
    name: "QuestionList",
    path: "/questionlistnew",
    layout: Async.PublicLayout,
    element: Async.MatrixMultiSelect,
  },
];

export default routesList;
