import { lazy } from "react";

export const LoginPage = lazy(
  () => import("../container/authentication/login")
);
export const ExamineePage = lazy(
  () => import("../container/examinee/examinee")
);
export const PrivateLayout = lazy(() => import("../layouts/privateLayout"));
export const PublicLayout = lazy(() => import("../layouts/publicLayout"));
export const Welcome = lazy(() => import("../container/welcome/welcome"));
export const QuestionList = lazy(() => import("../container/questionList"));
export const Examination = lazy(() => import("../container/Examination"));
export const FanDiagram = lazy(
  () => import("../components/OptionFormat/FanDiagramField")
);
export const MultipleBlank = lazy(
  () => import("../components/OptionFormat/MultipleBlankField")
);
export const MatrixSingleSelect = lazy(
  () => import("../components/OptionFormat/MatrixSingleSelectField")
);
export const MatrixMultiSelect = lazy(
  () => import("../components/OptionFormat/MatrixMultiSelectField")
);
